<template>
  <div v-if="item != null" class="user-page">
    <div class="content-wrapper" :class="{ 'debug-background': item.debug }">
      <h2>{{ item.title[default_lang] }}</h2>
    </div>
    <b-card title="Campaign Mission">
      <b-row>
        <b-col md="9" xl="6">
          <b-form-group label-for="role_list">
            <template #label>
              Cover image of the mission
              <InfoPopover
                class="ml-1"
                text="This is the first image user will see in at the start of this campaign"
              />
              <b-form-group class="pt-2">
                <b-form-checkbox v-model="item.show_cover_in_menu"
                  >Use image as "Item Background" in "Campaigns
                  Menu"</b-form-checkbox
                >
              </b-form-group>
            </template>

            <b-form-file
              class="drop-file"
              v-model="file"
              drop-placeholder="Drop file here..."
              accept="image/jpeg, image/png, image/gif"
            >
              <template #placeholder>
                <img
                  v-if="item.url_mission_cover.trim() != ''"
                  :src="item.url_mission_cover"
                  class="img-fluid uploader_image_preview"
                />
                <div v-else class="my-4 text-center">
                  <p class="h1 mb-1">
                    <b-icon-cloud-arrow-up-fill />
                  </p>
                  <p>
                    Choose a file or drop it here <br />
                    (.jpg, .png, .pdf, .gif)
                  </p>
                </div>
              </template>
              <template slot="file-name">
                <img :src="file_url" class="img-fluid uploader_image_preview" />
              </template>
            </b-form-file>
            <b-icon
              v-if="loadingFile"
              class="loading-file"
              icon="arrow-clockwise"
              animation="spin"
              font-scale="2"
            ></b-icon>
          </b-form-group>

          <b-form-group label="Description" label-for="description">
            <VueEditor
              class="editor"
              v-model="item.mission_description[mission_description_lang]"
            />
            <LangOptions
              v-model="mission_description_lang"
              :options="languages"
            />
            <!-- <b-form-textarea
							v-model="item.mission_description[mission_description_lang]"
							rows="3"
						/>
						<LangOptions
							v-model="mission_description_lang"
							:options="languages"
						/> -->
          </b-form-group>
          <b-form-checkbox v-model="item.avatar_personalizzati" class="mt-5">
            Allow custom avatars assigning
            <InfoPopover
              class="ml-1"
              text="Enable custom avatar to assign specific goals and benefits"
            />
          </b-form-checkbox>
          <b-form-checkbox v-model="item.assegnazione_badge" class="mt-2">
            Allow badge assigning
            <InfoPopover
              class="ml-1"
              text="Allow user to select specific badges to gain more points or combos"
            />
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <div class="content-wrapper">
      <b-button v-if="isLoading" squared variant="primary" size="lg" disabled>
        <b-spinner small type="grow"></b-spinner> Loading...
      </b-button>
      <b-button v-else squared variant="primary" size="lg" @click="saveItem"
        ><template v-if="hasNextRoute()">Save and continue</template
        ><template v-else>Save</template></b-button
      >
    </div>
    <b-button
      v-if="item.debug"
      class="fixed-button py-2"
      v-b-toggle.sidebar-iframe
    >
      <img src="../../assets/images/phone.png" class="img-fluid" />
    </b-button>
    <b-sidebar
      @hidden="resetDataSidebarIframe"
      class="right-sidebar iframe-sidebar"
      id="sidebar-iframe"
      width="500px"
      right
      shadow
      lazy
    >
      <div class="login-as">
        <h4>Login as:</h4>
        <b-select
          @change="getUrlDemo"
          :options="usersList"
          v-model="userSelected"
        >
        </b-select>
        <!-- user selectd: {{ userSelected }} || url demo: {{ url_demo }} -->
      </div>
      <div class="iframe-phone">
        <iframe
          class="iframe-phone-iframe"
          :src="url_demo"
          frameborder="0"
        ></iframe>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import AxiosService from "./../../axiosServices/AxiosService";
import * as CampaignRoutes from "@/utils/CampaignRoutes.js";
import { VueEditor } from "vue2-editor";
export default {
  components: { VueEditor },
  data() {
    return {
      isNewCampaign: this.$route.params.campaignId == null,

      url_demo: "",
      usersList: [{ value: null, text: "Seleziona Utente" }],
      userSelected: null,
      file: null,
      item: null,
      mission_description_lang: null,
      isLoading: false,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("Campaign");
    this.mission_description_lang = this.default_lang;
    console.log(this.default_lang);
    // this.service.readCustomEndpoint("Setting").then((res) => {
    //   if (res) {
    //     this.url_demo = res.url_demo;
    //   } else {
    //     console.err("ERROr calling Setting");
    //   }
    // });
  },
  mounted() {
    if (!this.isNewCampaign) {
      this.getUsersByCampaign();
    }
    this.service
      .readById(this.$route.params.campaignId)
      .then((data) => (this.item = data));
  },
  methods: {
    getUsersByCampaign() {
      this.usersList.splice(1);
      if (this.$route.params.campaignId) {
        this.service
          .readCustomEndpoint(
            "CampaignManager/GetUsersInCampaign",
            this.$route.params.campaignId
          )
          .then((res) => {
            res.forEach((element) => {
              this.usersList.push(element);
            });
          });
      } else {
        console.log("FAIL ");
      }
    },
    getUrlDemo() {
      if (this.userSelected != null) {
        this.service.readCustomEndpoint("Setting").then((res) => {
          if (res) {
            this.url_demo =
              res.url_demo +
              "/debug/" +
              this.$route.params.campaignId +
              "/" +
              this.userSelected +
              "/mision/campaign";
          } else {
            console.err("ERROr calling Setting");
          }
        });
      } else {
        this.url_demo = "";
        // this.service.readCustomEndpoint("Setting").then((res) => {
        //   this.url_demo = res.url_demo + "login";
        // });
      }
    },
    resetDataSidebarIframe() {
      this.userSelected = null;
      this.url_demo = "";
    },
    async saveItem() {
      this.isLoading = true;
      this.loadingFile = true;
      const formData = new FormData();

      if (this.file) {
        if (this.file.size > 3 * 1024 * 1024) {
          alert("I file non possono superare il massimo di 3MB");
          return;
        }
        formData.append("pic", this.file);
        await this.service
          .uploadFile(formData)
          .then((res) => {
            let baseUrl = this.$driveAddress;
            let imageUrl = baseUrl + res.data;
            this.item.url_mission_cover = imageUrl.replaceAll(" ", "%20");
          })
          .catch(() => {
            this.$errorToast();
            return false;
          })
          .finally(() => {
            this.loadingFile = false;
          });
      }

      this.service
        .update(this.item)
        .then((response) => {
          if (response.id == this.item.id) {
            this.$successToast("Mission saved");
            this.toNextRoute();
          } else {
            this.$errorToast();
            return false;
          }
        })
        .catch(() => {
          this.$errorToast();
          return false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    hasNextRoute() {
      return CampaignRoutes.hasNextRoute(this, "Mission");
    },
    toNextRoute() {
      CampaignRoutes.toNextRoute(this, "Mission");
    },
  },
  computed: {
    file_url() {
      if (this.file) {
        return URL.createObjectURL(this.file);
      } else {
        return "";
      }
    },
    languages() {
      return this.$parent.companyLanguages;
    },
    default_lang() {
      return this.$parent.default_lang;
    },
  },
};
</script>
